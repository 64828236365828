import React, { Component } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';

import Loading from '../../components/Loading';
import { Header } from '../../components/Reports';
import {
  Overview,
  Metadata,
  PermissionInfo,
  AnalysisDetails,
} from '../../components/Reports/Android';
import NetworkInfo from '../../components/Reports/NetworkInfo';
import ErrorHandler from '../../components/ErrorHandler';
import {
  setThreatScore,
  setResults,
  setReportItems,
  setAppIssues,
  setParsedAppIssues,
  setTraffic,
  setNiapResults,
  setNiapAppIssues,
  setOwaspMSTGResults,
  setOwaspMasvsAppIssues,
  setSbomResults,
  resetAppResultsState,
  setAvailableResults,
} from '../../store/slices/appResultsSlice';
import {
  setShowMap,
  setReportTab,
  setPageTitle,
} from '../../store/slices/emmAppSlice';
import TaiwanFindings from '../../components/Reports/TaiwanFindings';
import SimpleReport from '../../components/Reports/SimpleReport';
import OwaspDetails from '../../components/Reports/OwaspDetails';
import GDPRreport from '../../components/Reports/GDPRreport';
import NiapDetails from '../../components/Reports/NiapDetails';
import { SbomDetails } from '../../components/Reports/SbomDetails';
import androidReportUtil from '../../logic/androidReportUtil';

class AndroidStandard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentWillMount() {
    const { farmAppId } = this.props.match.params;
    const {
      setThreatScore,
      setResults,
      setReportItems,
      setAppIssues,
      setParsedAppIssues,
      setTraffic,
      setNiapResults,
      setNiapAppIssues,
      setOwaspMSTGResults,
      setOwaspMasvsAppIssues,
      setSbomResults,
      setAvailableResults,
    } = this.props;

    // Load the various pieces of data that we need for the report
    const getResults = () => axios.get(`api/results/json?uuid=${farmAppId}`);
    const getThreatScore = () =>
      axios.get(`api/threat-score?uuid=${farmAppId}`);
    const getReportItems = () => axios.get('api/config/report-items/android');
    const getAppIssues = () => axios.get(`app-issues?uuid=${farmAppId}`);
    const getParsedAppIssues = () =>
      axios.get(`app-issues/parsed?uuid=${farmAppId}&evidence=true`);
    const getOwaspMstgResults = () =>
      axios.get(`api/results/owasp_mstg?uuid=${farmAppId}`);
    const getTraffic = () =>
      axios.get(`api/results/traffic_portal?uuid=${farmAppId}`);
    const getNiapResults = () =>
      axios.get(`api/results/niap_json?uuid=${farmAppId}`);
    const getSbomResults = () =>
      axios.get(`api/results/sbom?uuid=${farmAppId}`);
    const verifyAvailablePcap = () =>
      axios.get(`api/verify-results/${farmAppId}/network_pcap`);
    try {
      const [
        owaspMstgResults,
        results,
        threatScore,
        reportItems,
        appIssues,
        parsedAppIssues,
        traffic,
        niapResults,
        sbomResults,
        isPcapAvailable,
      ] = await axios.all([
        getOwaspMstgResults().catch(() => []),
        getResults(),
        getThreatScore(),
        getReportItems(),
        getAppIssues(),
        getParsedAppIssues(),
        getTraffic().catch(() => null),
        getNiapResults().catch(() => null),
        getSbomResults().catch(() => {}),
        verifyAvailablePcap().catch(() => null),
      ]);
      console.log('Retrieved app results:', results.data);
      // Update redux store
      setOwaspMSTGResults(owaspMstgResults.data);
      setResults(results.data);
      this.props.setPageTitle(androidReportUtil.getAppName(this.props.results));
      setThreatScore(threatScore.data.threatScore);
      setReportItems(reportItems.data);
      setAppIssues(appIssues.data.map(entry => entry.issue));
      setNiapAppIssues(
        appIssues.data.filter(entry => entry.issue.startsWith('niap_')),
      );
      setOwaspMasvsAppIssues(
        appIssues.data.filter(entry => entry.issue.startsWith('owasp_masvs')),
      );
      setParsedAppIssues(parsedAppIssues.data.parsedAppIssues);
      setTraffic(traffic && traffic.status === 200 ? traffic.data : null);
      if (
        niapResults &&
        'success' in niapResults.data &&
        !niapResults.data.success
      ) {
        setNiapResults(null);
      } else {
        setNiapResults(niapResults?.data ?? null);
      }
      setSbomResults(sbomResults.data);
      setAvailableResults({ pcap: isPcapAvailable.data });
    } catch (err) {
      console.log('Error getting app results:', err);
    } finally {
      window.scrollTo(0, 0);
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    const { setShowMap, setReportTab, resetAppResultsState } = this.props;
    setShowMap(false);
    setReportTab('0');
    resetAppResultsState();
  }

  render() {
    const { isLoading } = this.state;
    const { farmAppId, analyzedAppId } = this.props.match.params;
    const {
      results,
      threatScore,
      userAccess,
      showMap,
      reportTab,
      niapResults,
    } = this.props;

    if (isLoading) return <Loading style={{ marginTop: '40vh' }} />;

    return (
      <ErrorHandler type="androidReport">
        <div className="analysis-report">
          <Header
            platform="android"
            title="Android Application Analysis"
            marketInfo={results.market || results.analysis.store_info}
            threatScore={threatScore}
            userAccess={userAccess}
            farmAppId={farmAppId}
            analyzedAppId={analyzedAppId}
          />
          {/* Main report section tabs */}
          <TabContent className="report-tab-content" activeTab={reportTab}>
            <TabPane className="animated fadeIn" tabId="0">
              <SimpleReport platform="android" />
            </TabPane>
            <TabPane className="animated fadeIn" tabId="1">
              <Overview />
            </TabPane>
            <TabPane className="animated fadeIn" tabId="2">
              <Metadata />
            </TabPane>
            <TabPane className="animated fadeIn" tabId="3">
              <NetworkInfo showMap={showMap} platform="android" />
            </TabPane>
            <TabPane className="animated fadeIn" tabId="4">
              <PermissionInfo />
            </TabPane>
            <TabPane className="animated fadeIn" tabId="5">
              <AnalysisDetails />
            </TabPane>
            {!!userAccess.taiwan_results && (
              <TabPane className="animated fadeIn" tabId="6">
                <TaiwanFindings platform="android" />
              </TabPane>
            )}
            {!!userAccess.owasp && (
              <TabPane className="animated fadeIn" tabId="7">
                <OwaspDetails platform="android" showMap={showMap} />
              </TabPane>
            )}
            {!!userAccess.gdpr && (
              <TabPane className="animated fadeIn" tabId="8">
                <GDPRreport showMap={showMap} platform="android" />
              </TabPane>
            )}
            {!!userAccess.niap && (
              <TabPane className="animated fadeIn" tabId="9">
                <NiapDetails niapResults={niapResults} platform="android" />
              </TabPane>
            )}
            <TabPane className="animated fadeIn" tabId="10">
              <SbomDetails platform="android" farmAppId={farmAppId} />
            </TabPane>
          </TabContent>
        </div>
      </ErrorHandler>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp, threatProfile }) => ({
  results: appResults.results,
  threatScore: appResults.threatScore,
  userAccess: emmApp.userAccess,
  simpleReportView: emmApp.simpleReportView,
  threatItems: threatProfile.threatItems,
  showMap: emmApp.showMap,
  reportTab: emmApp.reportTab,
  niapResults: appResults.niapResults,
});

const mapDispatchToProps = {
  setThreatScore,
  setResults,
  setReportItems,
  setAppIssues,
  setParsedAppIssues,
  setTraffic,
  setReportTab,
  setShowMap,
  setNiapResults,
  setNiapAppIssues,
  setOwaspMSTGResults,
  setOwaspMasvsAppIssues,
  setSbomResults,
  setPageTitle,
  resetAppResultsState,
  setAvailableResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(AndroidStandard);
