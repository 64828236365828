import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import { useLatest } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import { QCol } from '../../Q-Components/QCol';
import { QRow } from '../../Q-Components/QRow';
import { QListGroup } from '../../Q-Components/QListGroup';
import { QListGroupItem } from '../../Q-Components/QListGroupItem';
import { QAutocomplete } from '../../Q-Components/QAutocomplete';
import { QInput } from '../../Q-Components/QInput';
import { QLabel } from '../../Q-Components/QLabel';

export const useBulkSelectSubGroups = (setSelectedUsers, usersOptions) => {
  const [subGroups, setSubGroups] = useState([]);
  const [selectedSubGroups, setSelectedSubGroups] = useState([]);
  const [subGroupsOptions, setSubGroupsOptions] = useState([]);

  // Add sub group to list
  const addSubGroupToList = option => {
    if (selectedSubGroups.some(subGroup => subGroup.value === option.value)) {
      return;
    }
    const subGroupsArray = new Array(option);
    const newSubGroups = [...selectedSubGroups, ...subGroupsArray];
    setSelectedSubGroups(newSubGroups);

    setSelectedUsers(users => {
      // Initialize a Map with the current users to keep track of unique users
      const userMap = new Map(users.map(user => [user.value, user]));

      // Add new users from usersOptions based on the condition
      usersOptions.forEach(user => {
        if (user.subGroups.some(subGroup => subGroup.id === option.value)) {
          userMap.set(user.value, user); // Adds or updates the user in the map
        }
      });

      // Convert the Map values back to an array
      return Array.from(userMap.values());
    });
  };

  // Delete sub group from list
  const deleteSubGroupFromList = option => {
    // What we do here is when we remove sub group from the list,
    // it will also remove all user belongs to that sub group from the list
    setSelectedUsers(users =>
      users.filter(
        user => !user.subGroups.some(subGroup => subGroup.id === option.value),
      ),
    );

    const newSubGroups = selectedSubGroups.filter(
      subGroup => subGroup.value !== option.value,
    );
    setSelectedSubGroups(newSubGroups);
  };

  return {
    selectedSubGroups,
    addSubGroupToList,
    deleteSubGroupFromList,
    subGroups,
    setSubGroups,
    subGroupsOptions,
    setSubGroupsOptions,
  };
};

export const useBulkSelectGroup = ({
  kaiAccess = false,
  setSubGroups,
  setSubGroupsOptions,
  setUsersOptions,
  users,
  setSelectedUsers,
}) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const addGroupToList = option => {
    setSelectedGroups([option]);

    setSubGroupsOptions([
      ..._.flatten(
        groups
          .filter(group => group.id === option.value)
          .map(adminGroup =>
            (adminGroup.sub_groups || adminGroup.subGroups).map(subGroup => ({
              value: subGroup.id,
              label: subGroup.name,
            })),
          ),
      ),
    ]);

    setSubGroups([
      ..._.flatten(
        groups
          .filter(group => group.id === option.value)
          .map(adminGroup =>
            (adminGroup.sub_groups || adminGroup.subGroups).map(
              subGroup => subGroup,
            ),
          ),
      ),
    ]);

    setUsersOptions(users.filter(user => user.userGroupId === option.value));
  };

  const deleteGroupFromList = option => {
    const newGroups = selectedGroups.filter(
      group => group.value !== option.value,
    );

    setSelectedUsers(users =>
      users.filter(user => user.userGroupId !== option.value),
    );

    setSelectedGroups(newGroups);
  };

  // Get group info
  async function getGroupInfo() {
    const { data } = kaiAccess
      ? await axios.get('adminV2/groups')
      : await axios.get('group-admin/config');

    setGroups(kaiAccess ? data : [data]);
  }

  return {
    selectedGroups,
    addGroupToList,
    deleteGroupFromList,
    getGroupInfo,
    groups,
  };
};

export const useBulkSelectUsers = ({ kaiAccess = false }) => {
  const [usersOptions, setUsersOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const latestUsers = useLatest(selectedUsers);

  // Get user info based on selected sub groups
  async function getUsersInfo() {
    const { data } = kaiAccess
      ? await axios.get('adminV2/users')
      : await axios.get(`group-admin/users`);

    const userData = data.map(res => ({
      value: res.id,
      label: res.email,
      subGroups: res.subGroups,
      isAdmin: res.isAdmin,
      userGroupId: res.userGroup?.id,
    }));

    setUsers(userData);

    setUsersOptions(userData);
  }

  // Add user to list
  const addUserToList = option => {
    if (selectedUsers.some(user => user.value === option.value)) {
      return;
    }
    const usersArray = new Array(option);
    const newUsers = [...selectedUsers, ...usersArray];

    setSelectedUsers(newUsers);
  };

  // retrieves latest value, for when we need to use value in callback
  const get = () => latestUsers.current;

  // Delete user from list
  const deleteUserFromList = option => {
    const newUsers = selectedUsers.filter(user => user.value !== option.value);
    setSelectedUsers(newUsers);
  };

  return {
    users,
    addUserToList,
    setUsers,
    get,
    usersOptions,
    getUsersInfo,
    deleteUserFromList,
    setUsersOptions,
    selectedUsers,
    setSelectedUsers,
  };
};

export default function SubGroupsAndUsersCard(props) {
  const {
    addSubGroupToList,
    selectedSubGroups,
    deleteSubGroupFromList,
    usersOptions,
    addUserToList,
    users,
    deleteUserFromList,
    groups,
    kaiAccess,
    addGroupToList,
    deleteGroupFromList,
    selectedGroups,

    subGroupsOptions,
    selectedUsers,
  } = props;

  const { mastV2 } = useFlags();

  const groupsOptions = groups.map(group => ({
    value: group.id,
    label: group.name,
  }));

  const { groupAdminGroup } = useSelector(state => state.emmApp);

  const defaultGroup = groupAdminGroup.map(group => ({
    value: group.id,
    label: group.name,
  }))[0];

  useEffect(() => {
    if (groupsOptions.length > 0) {
      addGroupToList(groupsOptions[0]);
    }
  }, [groupsOptions.length > 0, users.length > 0]);

  return (
    <QCol
      xs="5"
      className="bg-light p-3 ml-3 mr-3"
      sx={{ marginRight: '16px' }}
    >
      <QRow className="justify-content-center">
        <h5 className="text-center">Bulk Selection</h5>
      </QRow>
      <hr />
      {mastV2 ? (
        <>
          <QLabel for="group-select">
            <strong style={{ color: 'black' }}>Select Group</strong>
          </QLabel>
          <QAutocomplete
            id="group-select"
            options={groupsOptions}
            disableClearable
            disabled={!kaiAccess}
            defaultValue={defaultGroup}
            onChange={(event, value) => {
              addGroupToList(value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={params => (
              <QInput
                {...params}
                variant="standard"
                style={{
                  textOverflow: 'ellipsis',
                  backgroundColor: '#FFFFFF',
                }}
              />
            )}
          />
        </>
      ) : (
        <>
          <strong>Select Group</strong>
          <Select
            options={groupsOptions}
            isDisabled={!kaiAccess}
            onChange={option => addGroupToList(option)}
          />
        </>
      )}
      <br />
      <strong>Group</strong>
      <br />
      {selectedGroups.length > 0 ? (
        <QListGroup className="mt-1">
          {selectedGroups.map((group, i) => (
            <QListGroupItem
              color="warning"
              key={group.value}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              divider={i !== selectedGroups.length - 1}
            >
              {group.label}
              {kaiAccess && (
                <span onClick={() => deleteGroupFromList(group)}>
                  <i
                    className="fal fa-minus-circle pointer"
                    style={{ color: 'red' }}
                  />
                </span>
              )}
            </QListGroupItem>
          ))}
        </QListGroup>
      ) : (
        <i>No group selected</i>
      )}
      <hr />
      {mastV2 ? (
        <>
          <QLabel for="subgroup-select">
            <strong style={{ color: 'black' }}>Select Sub Groups</strong>
          </QLabel>
          <QAutocomplete
            id="subgroup-select"
            options={subGroupsOptions}
            disableClearable
            onChange={(event, value) => {
              addSubGroupToList(value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={params => (
              <QInput
                {...params}
                variant="standard"
                style={{
                  textOverflow: 'ellipsis',
                  backgroundColor: '#FFFFFF',
                }}
              />
            )}
          />
        </>
      ) : (
        <>
          <strong>Select Sub Groups</strong>

          <Select
            className="mt-1"
            options={subGroupsOptions}
            onChange={option => addSubGroupToList(option)}
          />
        </>
      )}
      <br />
      <strong>Sub Groups</strong>
      <br />
      {selectedSubGroups.length > 0 ? (
        <QListGroup className="mt-1">
          {selectedSubGroups.map((subGroup, i) => (
            <QListGroupItem
              color="warning"
              key={subGroup.value}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              divider={i !== selectedSubGroups.length - 1}
            >
              {subGroup.label}

              <span onClick={() => deleteSubGroupFromList(subGroup)}>
                <i
                  className="fal fa-minus-circle pointer"
                  style={{ color: 'red' }}
                />
              </span>
            </QListGroupItem>
          ))}
        </QListGroup>
      ) : (
        <i>No sub group selected</i>
      )}
      <hr />
      {mastV2 ? (
        <>
          <QLabel for="user-select">
            <strong style={{ color: 'black' }}>Select Users</strong>
          </QLabel>
          <QAutocomplete
            id="user-select"
            options={usersOptions}
            disableClearable
            onChange={(event, value) => {
              addUserToList(value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderInput={params => (
              <QInput
                {...params}
                variant="standard"
                style={{
                  textOverflow: 'ellipsis',
                  backgroundColor: '#FFFFFF',
                }}
              />
            )}
          />
        </>
      ) : (
        <>
          <strong className="mt-3">Select Users</strong>
          <Select
            className="mt-1"
            options={usersOptions}
            onChange={option => addUserToList(option)}
          />{' '}
        </>
      )}
      <br />
      <strong>Users</strong>
      <br />
      {selectedUsers.length > 0 ? (
        <QListGroup>
          {selectedUsers.map((user, i) => (
            <QListGroupItem
              color="warning"
              className="mt-1"
              key={user.value}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              divider={i !== selectedUsers.length - 1}
            >
              {user.isAdmin ? (
                <i className="far fa-user-crown" />
              ) : (
                <i className="far fa-user" />
              )}
              {user.label}{' '}
              <span onClick={() => deleteUserFromList(user)}>
                <i
                  className="fal fa-minus-circle pointer"
                  style={{ color: 'red' }}
                />
              </span>
            </QListGroupItem>
          ))}
        </QListGroup>
      ) : (
        <i>No user selected</i>
      )}
    </QCol>
  );
}
