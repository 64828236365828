import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { QUncontrolledButtonDropdown } from '../../Q-Components/QUncontrolledButtonDropdown';
import { QButton } from '../../Q-Components/QButton';
import { QDropdownSelectItem } from '../../Q-Components/QDropdownSelectItem';
import { QDropdownToggle } from '../../Q-Components/QDropdownToggle';
import { QDropdownMenu } from '../../Q-Components/QDropdownMenu';
import { QMenuButtonWithIcon } from '../../Q-Components/QMenuButtonWithIcon';
import { QActionSelect } from '../../Q-Components/QActionSelect';
import i18n from '../../../localization/i18n';

import { ContinuousResubmissionModal } from './components/ContinuousResubmissionModal';

export function KaiAppActions({ analyzedApp, resubmitApp }) {
  const handleFocus = event => {
    event.target.style.background = 'none';
  };
  const { mastV2 } = useFlags();
  const [showContinuousResubmissionModal, setShowContinuousResubmissionModal] =
    useState(false);

  return (
    <>
      <ContinuousResubmissionModal
        isOpen={showContinuousResubmissionModal}
        toggle={() =>
          setShowContinuousResubmissionModal(!showContinuousResubmissionModal)
        }
        analyzedAppId={analyzedApp.id}
      />
      {mastV2 ? (
        <QActionSelect
          selectProps={{
            'data-testid': 'dropdown-menu-button',
          }}
          actionOptions={[
            {
              value: 'details',
              label: 'Details',
              icon: <i className="fas fa-info-circle fa-lg" />,
              link: `/kai/farm-apps/${analyzedApp.farmApp.id}`,
            },
            {
              value: 'continuousResubmission',
              label: 'Continuous Watchlist Monitoring',
              icon: <AutorenewIcon />,
              onClick: () => setShowContinuousResubmissionModal(true),
            },
            {
              label: 'Resubmit Farm App',
              value: 'resubmit_farm_app',
              icon: <i className="fas fa-sync-alt primary-icon" />,
              onClick: () => resubmitApp(analyzedApp.farmApp.id),
            },
          ]}
        />
      ) : (
        <QUncontrolledButtonDropdown
          data-testid="kai-actions-dropdown"
          variant="filled"
          style={{
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
          className="mx-1"
          title="Actions"
          sx={{ width: '120px' }}
        >
          <QDropdownToggle
            caret
            className="toggledown-primary"
            size="sm"
            disableAnimation
            shrink={false}
            style={{
              transform: 'none',
              overflow: 'visible',
            }}
          >
            <QButton
              variant="outlined"
              endIcon={<ExpandMore />}
              className="button-toggledown-primary"
            >
              Actions
            </QButton>
          </QDropdownToggle>
          <QDropdownMenu
            variant="filled"
            IconComponent={null}
            value=""
            displayEmpty
            style={
              mastV2
                ? {
                    paddingTop: '0px',
                    minWidth: 'unset',
                    background: 'none',
                  }
                : {}
            }
            renderValue={() => {}}
            onFocus={handleFocus}
          >
            {/* First item is always selected in MUI by default so this is to get rid of that behavior. */}
            <QDropdownSelectItem value="" style={{ display: 'none' }} />
            <Link to={`/kai/farm-apps/${analyzedApp.farmApp.id}`}>
              <QDropdownSelectItem title="Details">
                <QMenuButtonWithIcon
                  icon={<i className="fas fa-info-circle fa-lg" />}
                  label="Details"
                />
              </QDropdownSelectItem>
            </Link>
            <QDropdownSelectItem
              title="Continuous Watchlist Monitoring"
              onClick={() => setShowContinuousResubmissionModal(true)}
            >
              <QMenuButtonWithIcon
                icon={<i className="fas fa-info-circle fa-lg" />}
                label="Continuous Watchlist Monitoring"
              />
            </QDropdownSelectItem>
            <QDropdownSelectItem
              onClick={() => resubmitApp(analyzedApp.farmApp.id)}
            >
              <QMenuButtonWithIcon
                icon={<i className="fas fa-sync-alt primary-icon" />}
                label={i18n.t('Resubmit Farm App')}
              />
            </QDropdownSelectItem>
          </QDropdownMenu>
        </QUncontrolledButtonDropdown>
      )}
    </>
  );
}

export default withLDConsumer()(KaiAppActions);
